export const environment = {
    IsADLogin: true,
	GatewayURL: "https://stage.api.aquamasterplus.measurementservice.abb.com",
	maxDevicesPerGroup: 10,
	maxGroups: 10,
	maxInputLimit:50,
	maxInputInstanceLimit:50,
	appName: "AquaMaster+",
    production: true,
    clientId:'01acb86c-d22f-49e6-802f-5bce0494a10d',
    endpoint: 'https://stage.api.aquamasterplus.measurementservice.abb.com',
    readScope: 'api://c9a1602d-5646-4fe2-adb2-6d1bdcc9c86b/Read',
    writeScope: 'api://c9a1602d-5646-4fe2-adb2-6d1bdcc9c86b/Write'
  };